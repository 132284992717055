import React from 'react';
import {
    Row, Col
} from 'reactstrap';

import blank from '../static/images/logo-horizontal/MONOCHROME/BD - RVB_Logo Horizontal - CREME.png'
import insta from '../static/images/instagram.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom'


function MyFooter() {


    return (

        <section id="about-me" className=" ">
            <div className="my-container pb-0">
                <div className="container-fluid my-rounded px-0 bg-gold d-flex justify-content-center pb-0">
                    <Col className='mx-auto' md={9}>
                        <Row className='p-5'>
                            <Col md={4} className='better-together big text-uppercase text-white'>
                                <LazyLoadImage className='w-100' src={blank} alt='Le biscuit iodé, le café céramique de Concarneau' />
                            </Col>
                            <Col md={4} className=' big text-white my-auto pt-md-0 py-3'>
                                <p className='my-0 py-0 better-together huge text-uppercase' style={{ lineHeight: 0.5 }}>Tenter l aventure <span className='patrick-hand'>?</span></p>
                                <p className='my-0 py-0 patrick-hand text-sand pb-2' >Reservez ou contactez-moi</p>

                                <div className='pt-1'>
                                    <a href="tel:0982741291" style={{ lineHeight: 0.8 }} className='nav-link text-white hover-terra d-inline-block patrick-hand spaced-text' >09.82.74.12.91.</a>
                                </div>
                                <div className='pt-1'>
                                    <a href="mailto: contact@biscuit-iode.fr" style={{ lineHeight: 0.8 }} className='nav-link text-white hover-terra patrick-hand spaced-text' >contact@biscuit-iode.fr</a>
                                </div>
                                <div className='pt-2'>
                                    <a target='_blank' rel="noreferrer" style={{ lineHeight: 0.8 }} href="https://www.google.com/maps/dir/?api=1&destination=9 rue Dumont d'Urville 29000 concarneau" className='nav-link text-white hover-terra patrick-hand' >9 rue Dumont d’Urville 29900 Concarneau</a>
                                </div>
                            </Col>
                            <Col md={4} className=' big text-white my-auto h-100 pt-md-0 pt-4'>
                                <p style={{ lineHeight: 0.5 }} className='my-0 better-together huge text-uppercase'>Suivre les actualites du cafe</p>
                                <div className='pt-3'>
                                    <a target='_blank' rel="noreferrer" href='https://instagram.com/lebiscuitiode' className='nav-link pointer text-white hover-terra d-inline-block' ><LazyLoadImage alt='Regjoignez-moi sur les réseaux sociaux et restez au courant des actualités liées au Biscuit iodé.' width={"20px"} src={insta} /> Le biscuit iodé</a>
                                </div>


                            </Col>
                        </Row>
                    </Col>

                </div>
            </div>
            <div className='py-3 justify-content-center w-100 d-flex'>
                <Link className={"nav-link px-2 px-md-3"} to={"/legal-notice"}>Mentions légales</Link>
                <Link className={"nav-link px-2 px-md-3"} to={"/use-condition"}>Conditions générales d'utilisation</Link>
                <Link className={"nav-link px-2 px-md-3"} to={"/cookie-policy"}>Politique de cookie</Link>
                <Link className={"nav-link px-2 px-md-3"} to={"/privacy-policy"}>Politique de confidentialité</Link>
            </div>
            <div className='pb-3 justify-content-center w-100 d-flex text-gold'>
                © Le biscuit iodé, 2024. Tous droits réservés.
            </div>


        </section>

    );
}

export default MyFooter;