import React from 'react';
import {
    Navbar,
    NavbarBrand
} from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import logo from '../static/images/logo-horizontal/MONOCHROME/BD - RVB_Logo Horizontal - OCRE.png'

function MyNavbar() {


    return (
        <div style={{ height: "150px", zIndex:"9999" }} className='position-fixed w-100 shadow'>
            <Navbar className='bg-imaged my-auto h-100 px-md-5 ' expand="md">
                <NavbarBrand className='text-white p-0 m-0 mx-auto' href="/">
                    <LazyLoadImage alt="Le biscuit iodé, le café céramique de Concarneau." style={{ maxHeight: "150px", maxWidth: "100%" }} src={logo} />
                </NavbarBrand>
            </Navbar>
        </div >
    );
}

export default MyNavbar;