import React from "react"

import {
    Container
} from 'reactstrap';
import { Link } from 'react-router-dom'


const Error404 = () => {

    return (
        <div>

                <Container fluid className="my-container pb-0 mb-0">
                    <div className="container-fluid p-5 mt-5 shadow text-gold text-center " style={{ backgroundColor: "#F0EDE2", height: "35vh" }}>
                        <h2 className="py-4">Cette page n'existe pas</h2>
                        <Link className="mt-3 nav-link primary-btn btn-lg white py-2 px-4 mx-auto" to="/">Retourner vers le site</Link>

                    </div>
                </Container>

        </div>
    )


}

export default Error404
