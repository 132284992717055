import React from "react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MyNavbar from "./components/navbar"
import MyFooter from "./components/footer"
import Home from "./routes/home"
import LegalNotice from "./routes/legal-notice"
import Error404 from "./routes/404"
import { Helmet } from 'react-helmet';
import UseCondition from "./routes/use-condition"
import CookiePolicy from "./routes/cookie-policy"
import PrivacyPolicy from "./routes/privacy-policy"


function RoutesManager() {

    return (

        <Router>
            <Helmet>
                <html lang="fr" />
                <title>Le Biscuit iodé - Le nouveau café céramique de Concarneau</title>
                <link rel="canonical" href="https://biscuit-iode.com" />
                <meta name="description" content="Tasses, vases, pichets, bols, coquetiers... Venez partager un moment de convivialité et de créativité sur un support en céramique. Le tout autour d'un bon café." />
                <link rel="icon" href="https://biscuit-iode.fr/favicon.ico?v=2" />
            </Helmet>

            <div className="bg-imaged">
                <MyNavbar />
                <Routes>
                    <Route path="" element={<Home />} />
                    <Route path="legal-notice" element={<LegalNotice />} />
                    <Route path="use-condition" element={<UseCondition />} />
                    <Route path="cookie-policy" element={<CookiePolicy />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<Error404 />} />
                </Routes>
                <MyFooter />
            </div>
        </Router>

    )

}
export default RoutesManager
