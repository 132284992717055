import React from "react"

import {
    Container
} from 'reactstrap';


const UseCondition = () => {

    return (
        <div>

            <Container fluid className="my-container pb-0 mb-0">
                <div className="container-fluid p-5 mt-5 shadow text-gold " style={{ backgroundColor: "#F0EDE2" }}>

                    <h2 className="text-center">Condition générales d'utilisation</h2>

                    <h3 className="pt-2">1. Objet du site </h3>
                    <p>Le site biscuit-iode.fr a pour objectif de présenter les activités de Le Biscuit iodé et de permettre aux utilisateurs de réserver une table via la plateforme Octotable.</p>

                    <h3>2. Accès au site</h3>
                    <p>Le site est accessible gratuitement à tout utilisateur disposant d’un accès à Internet.  </p>

                    <h3>3. Services proposés</h3>
                    <p>Le site propose un lien vers la plateforme Octotable, où les utilisateurs peuvent réserver gratuitement une table dans notre établissement. La gestion des réservations est réalisée exclusivement via Octotable. Nous ne collectons aucune donnée personnelle lors de ce processus.</p>

                    <h3>4. Responsabilité</h3>
                    <p>Le biscuit iodé ne pourra être tenu responsable des interruptions temporaires du site ou de la gestion des réservations effectuées sur Octotable.  </p>

                    <h3>5. Liens vers des sites tiers</h3>
                    <p>Le site contient un lien vers Octotable. Nous ne sommes pas responsables du contenu ou des politiques de cette plateforme. Nous vous invitons à consulter leurs conditions générales pour plus d’informations.  </p>

                    <h3>6. Modification des CGU</h3>
                    <p>Nous nous réservons le droit de modifier ces CGU à tout moment. Les utilisateurs sont invités à les consulter régulièrement.  </p>


                </div>
            </Container>

        </div>
    )


}

export default UseCondition
