import React from "react"

import {
    Container
} from 'reactstrap';


const LegalNotice = () => {

    return (
        <div>

            <Container fluid className="my-container pb-0 mb-0">
                <div className="container-fluid p-5 mt-5 shadow text-gold " style={{ backgroundColor: "#F0EDE2" }}>

                    <h2 className="text-center">Mentions légales</h2>

                    <p className="text-center">Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique (LCEN), <br/>les présentes mentions légales sont portées à la connaissance des utilisateurs du site biscuit-iode.fr.</p>

                    <h3>Propriétaire du site :</h3>
                    <p>Nom : Chloé Le Floc'h </p>
                    <p>Statut : Société à responsabilité limitée </p>
                    <p>Adresse : 9 Rue Dumont d'Urville, 29900 Concarneau </p>
                    <p>Téléphone : 09 82 74 12 91 </p>
                    <p>Adresse e-mail : contact@biscuit-iode.fr </p>
                    <p>Numéro SIRET : 93321993300019 </p>
                    <p>Code APE : 93.29Z </p>
                    <p>TVA : Non applicable (auto-entrepreneur, franchise de TVA, si applicable).  </p>

                    <h3>Hébergement du site :</h3>
                    <p>Le site est hébergé par o2Switch.  </p>
                    <p>Adresse : 222 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France.  </p>
                    <p>Téléphone : 04 44 44 60 40.  </p>

                    <h3>Responsable de la publication :</h3>
                    <p>Nom : Chloé Le Floc'h.</p>

                    <h3>Propriété intellectuelle :</h3>
                    <p>Tous les contenus présents sur ce site (textes, images, logos) sont la propriété exclusive de Le biscuit iodé, sauf mention contraire. Toute reproduction ou exploitation non autorisée est interdite.</p>

                    <h3>Contact :</h3>
                    <p>Pour toute question ou demande, vous pouvez nous contacter à contact@biscuit-iode.fr.</p>


                </div>
            </Container>

        </div>
    )


}

export default LegalNotice
