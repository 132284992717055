import React from "react"

import {
    Container
} from 'reactstrap';


const PrivacyPolicy = () => {

    return (
        <div style={{minHeight: "50vh" }}>

            <Container fluid className="my-container pb-0 mb-0">
                <div className="container-fluid p-5 mt-5 shadow text-gold  " style={{ backgroundColor: "#F0EDE2" }}>

                    <h2 className="text-center">Politique de confidentialité</h2>

                    <p>Le site biscuit-iode.fr ne collecte aucune donnée personnelle directement.</p>
                    <p>Toute donnée nécessaire à la réservation est collectée et gérée par la plateforme Octotable. Nous vous invitons à consulter leur politique de confidentialité pour comprendre comment vos données sont utilisées.</p>
                    <p>Pour toute question relative à vos droits concernant vos données personnelles, contactez directement Octotable.</p>



                </div>
            </Container>

        </div>
    )


}

export default PrivacyPolicy
