import React from "react"

import {
    Container
} from 'reactstrap';


const CookiePolicy = () => {

    return (
        <div style={{minHeight: "50vh" }}>

            <Container fluid className="my-container pb-0 mb-0" >
                <div className="container-fluid p-5 mt-5 shadow text-gold " style={{ backgroundColor: "#F0EDE2"}}>


                    <h2 className="text-center">Politique relative aux cookies</h2>

                    <p>Le site biscuit-iode.fr n’utilise pas de cookies pour collecter vos données personnelles. Aucun cookie de suivi ou publicitaire n’est installé sur votre appareil.
                    Cependant, des services tiers comme la plateforme Octotable (utilisée pour la réservation) peuvent utiliser leurs propres cookies. Nous vous invitons à consulter directement leur politique de confidentialité pour plus d’informations.</p>

                </div>
            </Container>

        </div>
    )


}

export default CookiePolicy
